import useQueryParams from "@/components/hook/use-params";
import { useModal } from "@/contexts/modal-provider";
import { useLocationStore } from "@/store/location";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/navigation";
import { RefObject, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CustomModal from "../../../../components/globals/custom-model";
import { OptionsFilterView } from "../../../../components/globals/filter/options/options.view";
import { FilterOptionsInfoState } from "../../../../components/globals/filter/options/options.types";
import { useMaps } from "@/components/globals/maps/maps.modal";
import GoogleMapsLoader from "@/config/api-loader";


const filter = z.object({
  location: z.string().optional(),
  priceRange: z.object({
    label: z.string(),
    values: z.tuple([z.number(), z.number()]),
  }),
});

type Props = {
  inputAutoComplete: RefObject<HTMLInputElement>;
};

export const useFilter = ({ inputAutoComplete }:Props) => {
  const router = useRouter()
  const { isLoaded } = GoogleMapsLoader();
  const { setPlace } = useLocationStore()
  const { objectToParams } = useQueryParams()
  const [isLoading] = useState(false);
  const { setOpen, setClose } = useModal();
  const form = useForm<z.infer<typeof filter>>({
    resolver: zodResolver(filter),
  });
  // const objToParams = (obj: FilterOptionsInfoState) => {
  //   const params = new URLSearchParams(searchParams.toString());
  //   Object.keys(obj).forEach((key) => {
  //     const value = obj[key as keyof FilterOptionsInfoState];
  //     if (Array.isArray(value)) {
  //       params.delete(key);
  //       value.forEach((val) => {
  //         params.append(key, val.toString());
  //       });
  //     } else if (value == null || value === "") {
  //       params.delete(key);
  //     } else {
  //       params.set(key, value.toString());
  //     }
  //   });
  //   return params.toString();
  // }


  const onSubmit = (obj: FilterOptionsInfoState) => {
    const params_ = objectToParams(obj);
    router.push(`?${params_}`);
    setClose();
  };


  const handleOpen = () => {
    setOpen(
      <CustomModal subheading="Todos os filtros que deseja" className="max-w-[600px] w-full">
        <OptionsFilterView isLoading={isLoading} onSubmit={onSubmit} />
      </CustomModal>
    );
  };

  useEffect(() => {
    if (isLoaded) {
      const gAutoComplete = new google.maps.places.Autocomplete(
        inputAutoComplete.current as HTMLInputElement,
        {
          types: ["geocode"],
          componentRestrictions: { country: "BR" },
        }
      );
      gAutoComplete.addListener("place_changed", () => setPlace(gAutoComplete.getPlace()));
    }
  }, [isLoaded, inputAutoComplete, setPlace]);

  return {
    onSubmit,
    handleOpen,
    form,
    inputAutoComplete,
  }
}