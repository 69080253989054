'use client'
import { deleteCookie } from '@/lib/cookies'
import { useHome } from './home.model'
import { HomeView } from './home.view'
import { userSessionContext } from '@/contexts/user-context'

export default function HomePage() {
 const methods = useHome()
  const { token, user, setToken, updateUser } = userSessionContext()
  deleteCookie('session.user')
  deleteCookie('favs')
  setToken("")
 return <HomeView {...methods} />
}
