import { Libraries, useJsApiLoader } from "@react-google-maps/api";
export const googleMapsLibraries = ["core", "maps", "places", "marker"];
const GoogleMapsLoader = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
    libraries: googleMapsLibraries as Libraries,
    language: "pt-BR",
  });

  return { isLoaded, caches };
};

export default GoogleMapsLoader;