import { FilterOptionsInfoState } from "./options.types"
import { useOptionFilter } from "./options.modal"
import { CategoriesFilter, PropertyCaracteristicos, PropertyOptions, PropertyType } from "@/constant/filters"
import { Input } from "@/components/ui/input"
import { Slider } from "@/components/ui/slider"
import { Button } from "@/components/ui/button"
import { ListRestart, Loader2, Search, Star } from "lucide-react"
import { Label } from "@/components/ui/label"
import { Combobox } from "@/components/ui/combobox"
import { Checkbox } from "@/components/ui/checkbox"
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { useState } from "react"


const QUANTITY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10']

type Props =  {
  isOrder?: boolean
  isLoading?: boolean
  onSubmit: (params: FilterOptionsInfoState) => void
}
export const OptionsFilterView = ({ onSubmit, isLoading, isOrder }:Props) => {
  const { handleResetFilter, handleSalveFilter, info, setInfo, handleOnChange } = useOptionFilter()
  const [rawPrices, setRawPrices] = useState(["0", ""]); // Estado para valores sem formatação

  const handleCheckboxChange = (isChecked: boolean, option: string) => {
    setInfo((prev) => {
      const updatedFeatures = isChecked
        ? [...(prev.feature || []), option]
        : prev.feature?.filter((feature) => feature !== option) || [];
      return { ...prev, feature: updatedFeatures };
    });
  };
  const handleCommandItemClick = (option: string) => {
    const isChecked = !info.feature?.includes(option);
    handleCheckboxChange(isChecked, option);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedRawPrices = [...rawPrices];
    updatedRawPrices[index] = value;
    setRawPrices(updatedRawPrices);
  };
  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  };
  
  const parseCurrency = (value: string) => {
    const numericValue = Number(value.replace(/[^\d,-]/g, '').replace(',', '.'));
    return isNaN(numericValue) ? 0 : numericValue;
  };
  
  const handleInputBlur = (index: number) => {
    const parsedValue = parseCurrency(rawPrices[index]);
    const updatedPrices = [...info.prices];
    updatedPrices[index] = parsedValue;
    setInfo((prev) => ({ ...prev, prices: updatedPrices }));

    const updatedRawPrices = [...rawPrices];
    updatedRawPrices[index] = formatCurrency(parsedValue);
    setRawPrices(updatedRawPrices);
  };

  const handleSliderChange = (values: any[]) => {
    setInfo((prev) => ({ ...prev, prices: values }));
    setRawPrices(values.map((value) => formatCurrency(value)));
  };

  return (
    <div className="w-full h-full">
      <div className="h-[500px] pr-3 overflow-y-auto scrollbar-custom">
        <div className="grid grid-cols-2 gap-x-2 gap-y-2 w-full overflow-x-auto py-2 scrollbar-custom">
          {
            CategoriesFilter.map((option) => (
              <div key={option} className={`border-[1px] col-span-1 text-center rounded-xl text-black px-5 py-1 text-sm ${info.transaction === option ? "border-blue-500 bg-blue-300" : "border-gray-300"} hover:border-blue-300 cursor-pointer`}
                onClick={() => {
                  if(info.transaction === option) {
                    handleOnChange("transaction", '')
                    return
                  }
                  handleOnChange("transaction", option)
                }}
              >
                {option}
              </div>
            ))
          }
        </div>
        <div className="flex flex-col my-2 gap-2">
          <Label>Tipo de imóvel</Label>
          <Combobox label={Array.isArray(info.types) && info.types.length > 0 ? info.types.join(", ") : "Tipo de imóvel"} className="w-full">
            <Command>
              <CommandGroup>
                <CommandList>
                  {PropertyType.map((option) => {
                    const isChecked = Array.isArray(info.types) && info.types.includes(option);
                    return (
                      <CommandItem
                        key={option}
                        className={`flex gap-4 cursor-pointer my-1.5 ${isChecked ? 'bg-blue-400' : ''}`}
                        onClick={() => handleCommandItemClick(option)}
                      >
                        <Checkbox
                          disabled={isLoading}
                          id={option}
                          className={`hover:text-blue-600 hover:border-blue-600 hover:bg-blue-200 ${
                            isChecked ? "text-blue-600 border-blue-600 bg-blue-200" : ""
                          }`}
                          checked={isChecked}
                          onCheckedChange={(isChecked) => {
                            let types = Array.isArray(info.types) ? info.types : [];
                            if(types.includes(option)){
                              types = types.filter(type => type !== option);
                            } else {
                              types = [...types, option];
                            }
                            handleOnChange("types", types)
                          }}
                        />
                        <label
                          htmlFor={option}
                          className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {option}
                        </label>
                      </CommandItem>
                    );
                  })}
                </CommandList>
              </CommandGroup>
            </Command>
          </Combobox>
        </div>
        <div>
          <Label>Preço</Label>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between gap-4">
              <Input
                disabled={isLoading}
                placeholder="R$10.000.000,00"
                className="h-10"
                value={rawPrices[0]} // Usa o estado bruto
                type="text"
                onChange={(e) => handleInputChange(0, e.target.value)}
                onBlur={() => handleInputBlur(0)}
              />
              <div className="h-[1px] w-[10rem] bg-[#d9d9d9] max-md:sr-only" />
              <Input
                disabled={isLoading}
                placeholder="R$10.000.000,00"
                className="h-10"
                value={rawPrices[1]} // Usa o estado bruto
                type="text"
                onChange={(e) => handleInputChange(1, e.target.value)}
                onBlur={() => handleInputBlur(1)}
              />
            </div>
            <div className="flex justify-center">
              <Slider
                value={info.prices.map((price) => parseFloat(price))} // Garante que o slider opere com números
                defaultValue={[0, 5000000]}
                onValueChange={handleSliderChange}
                max={5000000}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-3 gap-6">
          {
            PropertyOptions.map((qty) => (
              <div className="flex flex-col gap-1.5" key={qty.name}>
                <Label>{qty.label}</Label>
                <div className="w-full overflow-x-auto h-[50px] scrollbar-custom">
                  <div className="w-max flex gap-4">
                    {QUANTITY.map((value, index) => (
                      <Button 
                        key={index} 
                        variant="icon" 
                        onClick={() => {
                          if(info[qty.name as keyof FilterOptionsInfoState]){
                            handleOnChange(qty.name as any, null)
                            return
                          }
                          handleOnChange(qty.name as any, value)
                        }}
                        className={`text-blue-600 hover:border-blue-600 hover:bg-blue-200 ${info[qty.name as keyof FilterOptionsInfoState] === value ? "text-blue-600 border-blue-600 bg-blue-200" : ""}`}
                      >
                        {value}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="mt-3">
          <Label>Área do imóvel</Label>
          <div className="flex gap-2 justify-between">
            <div>
              <p className="text-[12px]">Area total (m²)</p>
                <Input placeholder="minimo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaTotalMin", Number(e.target.value))}/>
            </div>
            <div>
            <p className="text-sm">Area total (m²)</p>
              <Input placeholder="maximo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaTotalMax", Number(e.target.value))} />
            </div>
          </div>
          <div className="flex gap-2 mt-2 justify-between">
            <div>
              <p className="text-[12px]">Area Útil (m²)</p>
              <Input placeholder="minimo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaUtilMin", Number(e.target.value))} />
            </div>
            <div>
              <p className="text-sm">Area Útil (m²)</p>
              <Input placeholder="maximo ᵐ²" type="number" min={0} onChange={(e) => handleOnChange("areaUtilMax", Number(e.target.value))} />
            </div>
          </div>
        </div>  
        <Accordion type="single" className="w-full">
          {PropertyCaracteristicos.map((value, key) => (
            <AccordionItem value={value.label} key={key+value.label}>
              <AccordionTrigger>
                {value.label}
              </AccordionTrigger>
              <AccordionContent className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-4 gap-y-4  mt-2 transition-all duration-300 ease-in-out max-h-40 overflow-y-auto">
                {value.values.map((f, index) => (
                  <div key={f} className="col-span-1 flex items-center space-x-2">
                      <Checkbox
                        id={f}
                        disabled={isLoading}
                        className="cursor-pointer"
                        checked={info.feature?.includes(f)}
                        onCheckedChange={(e) => {
                          const isExist = info.feature?.includes(f);
                          if(isExist){
                            handleOnChange("feature", info.feature?.filter(() => e))
                          }else{
                            handleOnChange("feature", [...(info.feature || []), f])
                          }
                        }}
                      />
                      <label
                        htmlFor={f}
                        className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {f}
                      </label>
                    </div>
                  ))}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <div className="flex flex-col gap-4 static">
        <Button
          className="flex gap-4 py-4"
          onClick={() => onSubmit(info)}
          disabled={isLoading}
        >
          {isLoading ? <Loader2 className="animate-spin" /> : <Search size={20} />}
          {!isOrder ? "Buscar" : "Encomendar"}
        </Button>
        <div className="flex flex-wrap items-center justify-between gap-4 max-sm:justify-center">
          <div
            onClick={handleResetFilter}
            className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
          >
            <ListRestart />
            Resetar filtro
          </div>
          <div
            onClick={handleSalveFilter}
            className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
          >
            <Star />
            Salvar filtro
          </div>
        </div>
    </div>
  </div>
  )
}