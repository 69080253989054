"use client";
import { User } from "@/@types/next-auth";
import { deleteCookie, getCookie } from "@/lib/cookies";
import { jwtDecode } from "jwt-decode";
import { ReactNode, useContext, useState, createContext, useEffect } from "react";

type ContextType = {
  user: User | undefined;
  token: string | undefined;
  setToken(token: string): void;
  updateUser(value: Partial<User>): void;
};

const context = createContext<ContextType>({
  user: undefined,
  token: undefined,
  setToken: (token: string) => "",
  updateUser: (value: Partial<User>) => { },
});

type Props = {
  children: ReactNode;
};

export const UserSessionProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);

  const updateUser = (value: Partial<User>) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...value,
    }) as User);
  };

  const handleToken = (token: string) => {
    try {
      if (token) {
        const user = jwtDecode(token) as User || null;
        const favs = getCookie("favs") 
        if(favs) user.favorites = JSON.parse(favs)
        if(!user) return
        setToken(token)
        setUser(user)
      }

      setToken(token)
      setUser(user)
      return;
    } catch (error) {
      return
    }
  };
  useEffect(()=>{
    try {
      const session = getCookie("session.user") as string
      let token = JSON.parse(session ?? "{}").token as any
      if(!token) return;
      const user = jwtDecode(token) as User || null;
      const favs = getCookie("favs")
  
      if (favs) user.favorites = JSON.parse(favs)
      if (!user) return;
  
      setToken(token)
      setUser(user)
    } catch (error) {
    }
  }, [])
  return (
    <context.Provider value={{ user, token, setToken: handleToken, updateUser }}>
      {children}
    </context.Provider>
  );
};

export const userSessionContext = () => {
  return useContext(context);
};
