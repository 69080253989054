"use client";
import useQueryParams from "@/components/hook/use-params";
import { useClusterContext } from "@/components/providers/clusters-provider";
import { returnIdsLimitedParams } from "@/lib/utils";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Property } from "../@types/global";
import { QueryKeys } from "@/constant/query-key";
import { Routes } from "@/@types/api/routes.enum";
import { fetcher } from "@/lib/fetcher";
import { useLocationStore } from "@/store/location";

type PropsUseProperties = {
  size?: number;
  pag?: number;
  params?: string;
};

export const useProperties = ({ pag = 1, size = 12 }: PropsUseProperties) => {
  const { params } = useQueryParams();
  return useQuery({
    queryKey: QueryKeys.Properties(params),
    queryFn: async () => {
      const url = `${Routes.PropertiesPag}?size=${size}&pag=${pag}&sortBy=price${params && `&${params}`}`
      const response = await fetcher(url)
      return response
    },
    staleTime: 10 * (60 * 1000),
  });
};

export const getPropertiesCloseToMe = (
  location:
    | {
        lat: number;
        lng: number;
      }
    | undefined
) => {
  const { data } = useQuery({
    queryKey: QueryKeys.Properties(location?.lat, location?.lng),
    queryFn: async () => await fetcher(`${Routes.PropertiesPag}?latitude=${location?.lat}&longitude=${location?.lng}&radius=10`),
    staleTime: 7 * (60 * 1000),
  });

  return {
    propertiesCloseToMe: data ?? [],
  };
};

export const getPropertiesMap = () => {
  const { setLocation, place } = useLocationStore()
  const { params } = useQueryParams();
  let param = params
    .replace("type_list=list&", "")
    .replace("type_list=grid&", "");
  const address = useMemo(() => {
    if (place?.formatted_address) {
      setLocation({
        lat: place?.geometry?.location?.toJSON().lat!,
        lng: place?.geometry?.location?.toJSON().lng!,
      });
      return `&latitude=${
        place?.geometry?.location?.toJSON().lat
      }&longitude=${place?.geometry?.location?.toJSON().lng}&radius=4`;
    }
    return "";
  }, [place]);
  return useQuery({
    queryKey: QueryKeys.Map(param),
    queryFn: async () => {
      const output = await fetcher(`${Routes.PropertiesMap}?${param}${address}`)
      return output.data
    }
  });

};

export const getPropertiesBySlug = (slug: string) => {
  return useQuery<Property>({
    queryKey: QueryKeys.Properties(slug),
    queryFn: async () => {
      const response = await fetcher(`${Routes.PropertiesIdoOrSlug}?slug=${slug}`)
      return response
    },
  });
};

export const getPropertiesById = (id: number) => {
  return useQuery<Property>({
    queryKey: QueryKeys.Properties(id),
    queryFn: async () => {
      const response = await fetcher(`${Routes.PropertiesIdoOrSlug}?id=${id}`)
      return response
    },
  });
};

type GetPropertiesInfiniteScrollProps = {
  zoom?: number;
  maps?: boolean;
};

export const getPropertiesInfiniteScroll = ({ zoom = 12, maps = false }: GetPropertiesInfiniteScrollProps) => {
  const { ids } = useClusterContext();
  const { place } = useLocationStore();
  const { params } = useQueryParams();

  return useInfiniteQuery({
    queryKey: QueryKeys.Properties(zoom, ids.length, maps, params),
    queryFn: async ({ pageParam = 1 }) => {
      // Preparar URL
      let url = `${Routes.PropertiesPag}?size=12&pag=${pageParam}`;
      
      if (maps && ids?.length) {
        const output = returnIdsLimitedParams(ids.map(e => e.toString()), pageParam);
        if (output) url += `&ids=${output}`;
      }

      if (params) {
        url += `&${params}`;
      }

      // if (place) {
      //   const lat = place.geometry?.location?.lat();
      //   const lng = place.geometry?.location?.lng();
      //   if (lat && lng) {
      //     url += `&latitude=${lat}&longitude=${lng}&radius=4`;
      //   }
      // }

      return await fetcher(url);
    },
    getNextPageParam: (lastPage, allPages) => {
      const size = 12; // Tamanho da página
      const data = lastPage?.data || [];
      // Se a última página tiver menos itens que o tamanho esperado, não há mais páginas
      if (data.length < size) return undefined;
      return allPages.length + 1; // Próxima página
    },
    initialPageParam: 1,
    staleTime: 0,
  });
};