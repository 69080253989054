"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PropertyType } from "@/constant/filters";

type Props = {
  label: string;
  className?: string;
  children?: React.ReactNode;
};

export function Combobox({ label, className, children }: Props) {
  const [open, setOpen] = React.useState(false);
  const [buttonWidth, setButtonWidth] = React.useState<number>(0);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    if (open && buttonRef.current) {
      setButtonWidth(buttonRef.current.getBoundingClientRect().width);
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className="overflow-hidden">
        <Button
          ref={buttonRef}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
        >
          <div className="flex gap-2 justify-start">{label}</div>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("", className)} style={{ width: buttonWidth - 40 }}>
       {children}
      </PopoverContent>
    </Popover>
  );
}
