const URL = process.env.NEXT_PUBLIC_URL_BACKEND ?? "https://localhost:5000/api";
export const Routes = {
  //Customer
  ResetPass: `${URL}/customer/reset-password`,
  Register: `${URL}/customer/register`,
  Customer: `${URL}/customer`,
  Login: `${URL}/customer/login`,
  Inspect: `${URL}/customer/inspect/:id`,
  AuthGoogle: `${URL}/customer/signin-google`,
  ForgotPassword: `${URL}/customer/forgot-password`,
  DisabledAccount: `${URL}/customer/disabled-account`,
  //Categories
  Categories: `${URL}/categories`,
  //Favorites
  Favorites: `${URL}/favorites`,
  //Lead
  Lead: `${URL}/lead`,
  //Order
  Order: `${URL}/order`,
  //Panel
  PanelTicket: `${URL}/panel/ticket`,
  PanelOrder: `${URL}/panel/order`,
  PanelLead: `${URL}/panel/lead`,
  PanelCustomer: `${URL}/panel/customer`,
  //Properties
  Properties: `${URL}/properties`,
  PropertiesPag: `${URL}/properties/pag`,
  PropertiesMap: `${URL}/properties/map`,
  PropertiesIdoOrSlug: `${URL}/properties/idorslug`,
  //RealEstate
  RealEstateInvite: `${URL}/realestate/invite?max=0`,
  RealEstateRealtors: `${URL}/realestate/realtors`,
  //Subscript
  Subscript: `${URL}/subscript`,
  //Ticket
  Ticket: `${URL}/ticket`,
  //Viewer
  Viewer: `${URL}/viewer/top`,
} as const;
