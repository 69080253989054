"use client"
import { Routes } from "@/@types/api/routes.enum"
import { Property } from "@/@types/global"
import { userSessionContext } from "@/contexts/user-context"
import { setCookie } from "@/lib/cookies"
import { fetcher } from "@/lib/fetcher"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type Props = {
  data: Property
}

export const useCardModel = ({ data }: Props) => {
  const { user, token, updateUser } = userSessionContext()
  const fav = user?.favorites?.includes(data.id) ?? false
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const method = fav ? 'DELETE' : 'POST'
      const url = `${Routes.Favorites}?id=${id}`
      return await fetcher(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    },
    onSuccess: () => {
      const newFavStatus = fav
      const updatedFavorites = newFavStatus
      ? [...user!.favorites, data.id]
      : user!.favorites.filter((id) => id !== data.id)

      setCookie('favs', JSON.stringify(updatedFavorites), 30)
      updateUser({ favorites: updatedFavorites })
      toast.success(`Favorito ${fav ? 'removido' : 'adicionado'} com sucesso!`)
    },
  })

  const handleToggleFav = () => {
    if (!token) {
      toast.error('Você precisa estar logado para adicionar favoritos')
      return
    }
    mutation.mutate(data.id.toString())
  }
  return {
    handleSubmit: handleToggleFav,
    fav,
    user,
  }
}
