import useQueryParams from "@/components/hook/use-params"
import { useRouter } from "next/navigation"
import { useEffect, useState } from "react"
import { toast } from "sonner"
import { FilterOptionsInfoState } from "./options.types"

export const useOptionFilter = () => {
  const router = useRouter()
  const { obj } = useQueryParams()
  const [info, setInfo] = useState<FilterOptionsInfoState>({
    prices: [0,5000000000],
    types: [],
    transaction: "",
    feature: [],
    ...obj,
  })
  const handleSalveFilter = () => {
    toast.success("Salvo com sucesso!")
  }
  const handleResetFilter = () => {
    router.push("?")
    localStorage.removeItem('filter')
  }

  const handleOnChange = (key: keyof FilterOptionsInfoState, value: any) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    const filter = localStorage.getItem('filter')
    if (filter) {
      setInfo(JSON.parse(filter))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem('filter', JSON.stringify(info))
  }, [info])
  
  return {
    info,
    setInfo,
    handleSalveFilter,
    handleResetFilter,
    handleOnChange
  }
}