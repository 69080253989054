import {
  Home,
  House,
  Inbox,
  LineChart,
  Settings,
  ShieldAlert,
  Star,
  UserRoundPen,
} from 'lucide-react'

export const CategoriesFilter = ['Aluguel', 'Venda', 'Investimento', 'Temporada']
export const PropertyType = [
  'Apartamento',
  'Casa de condomínio',
  'Casa de vila',
  'Cobertura',
  'Comercial',
  'Andar / Laje corporativa',
  'Casa',
  'Conjunto / Sala',
  'Galpão / Depósito / Armazém',
  'Garagem',
  'Hotel / Motel / Pousada',
  'Loja / Salão / Ponto',
  'Prédop inteiro',
  'Terrenos / Lotes comerciais',
  'Comodo',
  'Fazenda / Sitio / Chácara',
  'Flat',
  'Industrial',
  'Fundos imobolíarios',
  'Loteamento',
  'Na planta',
  'Novo',
  'Parceria',
  'Kitnet',
  'Loft',
  'Sobrado',
  'Studio',
  'Terreno / Lote / Condomínio',
]
export const FILTER_HOME_IMOVEIS = [
  {
    values: [ 'Apartamento', 'Casa de condomínio', 'Casa de vila', 'Cobertura', 'Comercial', 'Andar / Laje corporativa', 'Casa', 'Conjunto / Sala', 'Galpão / Depósito / Armazém', 'Garagem', 'Hotel / Motel / Pousada', 'Loja / Salão / Ponto', 'Prédop inteiro', 'Terrenos / Lotes comerciais', 'Comodo', 'Fazenda / Sitio / Chácara', 'Flat', 'Industrial', 'Fundos imobolíarios', 'Loteamento', 'Na planta', 'Novo', 'Parceria', 'Kitnet', 'Loft', 'Sobrado', 'Studio', 'Terreno / Lote / Condomínio'],
    label: 'Tipo de imovel',
    key: 'types',
  },
  {
    values: CategoriesFilter,
    label: 'Categoria',
    key: 'transaction',
  },
  {
    values: ["asc", "desc"],
    label: 'Preço',
    key: 'sortdir',
  },
]

export const PropertyOptions = [
  {
    name: 'bedrooms',
    label: 'Quartos',
  },
  {
    name: 'bathrooms',
    label: 'Banheiros',
  },
  {
    name: 'garages',
    label: 'Garagem',
  },
  {
    name: 'suites',
    label: 'Suites',
  },
]


// export const FILTER_MODAL_IMOVEIS = {
//   combobox: [
//     {
//       name: 'bathrooms',
//       label: 'Banheiros',
//       values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
//     },
//     {
//       name: 'suites',
//       label: 'Suites',
//       values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
//     },
//     {
//       name: 'bedrooms',
//       label: 'Quartos',
//       values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
//     },
//     {
//       name: 'garages',
//       label: 'Garagem',
//       values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
//     },
//     {
//       name: 'rooms',
//       label: 'Sala',
//       values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
//     },
//   ],
// }

export const SIDE_BAR = [
  {
    icon: Home,
    path: '/',
    label: 'Home',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin', 'member'],
  },
  {
    icon: LineChart,
    path: '/dashboard',
    label: 'Painel',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin', 'member'],
  },
  {
    icon: Inbox,
    path: '/dashboard/inbox',
    label: 'Caixa de entrada',
    lvl: ['realtor', 'imobiliaria', 'admin', 'proprietário', 'member'],
  },
  {
    icon: Star,
    path: '/dashboard/favorite',
    label: 'Favoritos',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin', 'member'],
  },
  {
    icon: ShieldAlert,
    label: 'Administrativo',
    lvl: ['realtor', 'imobiliaria', 'admin', 'proprietário', 'member'],
    values: [
      {
        label: 'Meus anuncios',
        path: '/dashboard/admin/ads',
        lvl: ['realtor', 'imobiliaria', 'admin', 'proprietário', 'member'],
      },
      {
        label: 'Encomendas',
        path: '/dashboard/admin/orders',
        lvl: ['realtor', 'admin', 'proprietário', 'member'],
      },
      {
        label: 'Meus realtor',
        path: '/dashboard/admin/broker',
        lvl: ['imobiliaria', 'admin'],
      },
    ],
  },
  {
    icon: Settings,
    label: 'Configurações',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin'],
    values: [
      {
        label: 'Privacidade',
        path: '/dashboard/settings/privacy',
        lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin'],
      },
      {
        label: 'Admin',
        path: '/dashboard/settings/admin',
        lvl: ['admin'],
      },
    ],
  },
  {
    icon: UserRoundPen,
    path: '/dashboard/profile',
    label: 'Perfil',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin', 'member'],
  },
  {
    icon: House,
    path: '/formulary',
    label: 'Anuncie',
    lvl: ['imobiliaria', 'proprietário', 'realtor', 'admin', 'member'],
  },
]

export const FORM_CREATE_IMOVEIS = {
  description: {
    label: 'Descrição',
  },
  details: {
    label: 'Midia',
  },
  feature: {
    label: 'Localização',
  },
  location: {
    label: 'Detalhes',
  },
  midia: {
    label: 'Características',
  },
}

export const PropertyCaracteristicos = [
  {
    label: 'Características do imóvel',
    values: ['Aceita Pets',  'Ar-Condicionado',  'Área de Serviço',  'Armário embutido',  'Armário embutido no quarto',  'Armário no banheiro',  'Armários na cozinha',  'Box blindex',  'Chuveiro a gás',  'Closet',  'Conexão à internet',  'Cozinha americana',  'Cozinha grande',  'Depósito',  'Escritório',  'Interfone',  'Janelas grandes',  'Lareira',  'Mobiliado',  'Quintal']
  },
  {
    label: 'Condomínio',
    values: ['Acesso para deficientes',  'Bicicletário',  'Elevador',  'Gerador elétrico',  'Lavanderia',  'Recepção',  'Sauna',  'SPA']
  },
  {
    label: 'Lazer e Esporte',
    values: ['Academia',  'Churrasqueira',  'Cinema',  'Espaço Gourmet',  'Espaço verde/Parque',  'Jardim',  'Piscina',  'Playground',  'Quadra Poliesportiva',  'Quadra de Tênis',  'Salão de Festas',  'Salão de Jogos',]
  },
  {
    label: 'Segurança',
    values: [ 'Circuito de Segurança', 'Condomínio Fechado', 'Portão eletrônico', 'Portaria 24h', 'Sistema de alarme']
  }
]

export const REGISTER_IMOVEIS_OPTIONS = {
  'Características do imóvel': [
    'Aceita Pets',
    'Ar-Condicionado',
    'Área de Serviço',
    'Armário embutido',
    'Armário embutido no quarto',
    'Armário no banheiro',
    'Armários na cozinha',
    'Box blindex',
    'Chuveiro a gás',
    'Closet',
    'Conexão à internet',
    'Cozinha americana',
    'Cozinha grande',
    'Depósito',
    'Escritório',
    'Interfone',
    'Janelas grandes',
    'Lareira',
    'Mobiliado',
    'Quintal',
  ],
  Condomínio: [
    'Acesso para deficientes',
    'Bicicletário',
    'Elevador',
    'Gerador elétrico',
    'Lavanderia',
    'Recepção',
    'Sauna',
    'SPA',
  ],
  'Lazer e Esporte': [
    'Academia',
    'Churrasqueira',
    'Cinema',
    'Espaço Gourmet',
    'Espaço verde/Parque',
    'Jardim',
    'Piscina',
    'Playground',
    'Quadra Poliesportiva',
    'Quadra de Tênis',
    'Salão de Festas',
    'Salão de Jogos',
  ],
  Segurança: [
    'Circuito de Segurança',
    'Condomínio Fechado',
    'Portão eletrônico',
    'Portaria 24h',
    'Sistema de alarme',
  ],
}
export type REGISTER_IMOVEIS_VALUES = keyof typeof REGISTER_IMOVEIS_OPTIONS
export const REGISTER_IMOVEIS_OPTIONS_KEYS = {
  'Características do imóvel': 'features_of_the_property',
  Condomínio: 'condominium',
  'Lazer e Esporte': 'leisure_and_sport',
  Segurança: 'security',
}

export const TYPE_USER = [
  'Membro/Proprietario',
  'realtor',
  'Imobiliaria',
] as const
