"use client";
import { Property } from "@/@types/global";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Bath, X } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState, useEffect, ReactNode, useContext, createContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Skeleton } from "@/components/ui/skeleton";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "@/constant/query-key";
import { Routes } from "@/@types/api/routes.enum";
import { fetcher } from "@/lib/fetcher";
import { returnIdsLimitedParams } from "@/lib/utils";
import { CardDescription } from "@/components/ui/card";
import Image from "next/image";
import { deleteCookie } from "@/lib/cookies";

type ContextType = {
  isOpen: boolean;
  setOpen(value: boolean): void;
  setIds(value: string[]): void;
};
const context = createContext<ContextType>({
  isOpen: false,
  setOpen: () => {},
  setIds: () => {},
});

type Props = {
  children: ReactNode;
};
export const ViewsPropertiesProvider = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [ids, setIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const url = usePathname();
  const { ref, inView } = useInView();
  const { fetchNextPage, data, isLoading } = useInfiniteQuery({
    queryKey: QueryKeys.Properties(ids),
    queryFn: async ({ pageParam = 0 }) => {
      const output = returnIdsLimitedParams(
        ids?.map((e) => e?.toString()),
        pageParam
      );
      if(url != "/") throw new Error();
      return await fetcher(`${Routes.PropertiesPag}?size=6&ids=${output}`)
    },
    getNextPageParam: (lastPage: any) => {
      const size = 12; 
      const data = lastPage?.data?.data || [];
  
      if (data.length < size) {
        return undefined;
      }
      return lastPage.data.next;
      },
    initialPageParam: 0,
    staleTime: 0,
  })
  const handleOpen = (value: boolean) => {
    if(!value) setIds([]) 
    setIsOpen(value);
  }
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) return null;
  return (
    <context.Provider value={{ isOpen, setOpen: handleOpen, setIds }}>
      {children}
      {url == "/" && isOpen && (
        <div className="fixed bottom-2 p-2 flex z-[500] w-full flex-col items-center justify-center">
          <div
            onClick={() => setIsOpen(false)}
            className="z-[800] right-2 -top-7 cursor-pointer absolute bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
          >
            <X size={20} />
          </div>
          <Swiper
            className="w-full"
            spaceBetween={30}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            slidesPerView={1.5}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            breakpoints={{
              300: { slidesPerView: 1.5, spaceBetween: 10 }, // Em telas menores, exibe 1.2 slides
              768: { slidesPerView: 3.5, spaceBetween: 20 }, // Em telas médias, exibe 1,5 slides
              1024: { slidesPerView: 4.5, spaceBetween: 30 }, // Em telas maiores, exibe 2,5 slides
              1200: { slidesPerView: 5.5, spaceBetween: 30 }, // Em telas muito grandes, exibe 3,5 slides
            }}
          >
            {data?.pages.map((page) =>
              page?.data?.map((card: any) => (
                <SwiperSlide key={`card-slider-${card.id}`}>
                  <CardView data={card} />
                </SwiperSlide>
              ))
            )}
            <div className="grid w-full gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div ref={ref} />
              {isLoading && Array.from({ length: 10 }).map((_, idx) => (
                <SwiperSlide className="h-[150px]">
                  <Skeleton key={idx} className="h-[250px] w-full" />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      )}
        
    </context.Provider>
  );
};

export const useViewsPropertiesContext = () => {
  return useContext(context);
};

const CardView = ({ data }: { data: Property }) => {
  const imgs = data.attachments.slice(0, 3).map((img, index) => {
    if (index < 3)
      return (
        <CarouselItem className="w-full h-[150px] rounded-t-md" key={index}>
          <img
            src={img}
            alt={data.title}
            className="w-full object-fill h-full rounded-t-md"
          />
        </CarouselItem>
      )
  })

  return (
    <div className="w-full text-sm flex flex-col">
      <Carousel className="relative flex w-full">
        <CarouselContent>{imgs}</CarouselContent>
        <CarouselPrevious className="absolute left-0 bg-white" />
        <CarouselNext className="absolute right-0 bg-white" />
      </Carousel>
      <Link href={`/property/${data.slug}`} className="flex flex-col bg-white p-2 rounded-b-md gap-3">
        <h1 className="capitalize text-base font-bold truncate">{data.title}</h1>
        <p className="truncate">{data?.location?.fullAddress}</p>
        <div className="flex gap-3 justify-between w-full ">
        <CardDescription className="flex gap-2">
            <Image src={'/bad.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-x text-[#7B818F] truncate">{data?.details?.bedrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Bath size={20} className="text-[#7B818F]" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={'/subway.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal} m²</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={'/garage.svg'} width={20} height={20} alt="icone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.garages}</p>
          </CardDescription>
          {/* {[
            {
              icon: (
                <svg viewBox="0 0 24 24" className="w-4 h-4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 3A1.75 1.75 0 0 0 7 4.75v10.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 21 15.25V4.75A1.75 1.75 0 0 0 19.25 3H8.75ZM10 15a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-8Z" fill="currentColor" />
                  <path d="M6 19a1 1 0 0 1-1-1V6a1 1 0 0 0-2 0v13a2 2 0 0 0 2 2h13a1 1 0 1 0 0-2H6Z" fill="currentColor" />
                </svg>
              ),
              label: <p className="max-w-full truncate">{data?.details.areaTotal} m²</p> ,
            },
            {
              icon: (
                <svg viewBox="0 0 24 24" className="w-4 h-4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 3a3 3 0 0 1 3 3v3c1.214.912 2 2.364 2 4v7a1 1 0 0 1-1 1h-2v-2H5v2H3a1 1 0 0 1-1-1v-7c0-1.636.786-3.088 2-6V6a3 3 0 0 1 3-3h3c.768 0 1.47.29 2 .764A2.989 2.989 0 0 1 14 3h3ZM6 6v2h5V6c0-.551-.449-1-1-1H7c-.551 0-1 .449-1 1Zm14 7a2.98 2.98 0 0 0-.879-2.121A2.98 2.98 0 0 0 17 10H7a2.98 2.98 0 0 0-2.121.879A2.98 2.98 0 0 0 4 13v1h16v-1Zm0 4v-1H4v1h16ZM13 6v2h5V6c0-.551-.449-1-1-1h-3c-.551 0-1 .449-1 1Z" fill="currentColor" />
                </svg>
              ),
              label: `${data?.details.bathrooms}`,
            },
            {
              icon: (
                <svg viewBox="0 0 24 24" className="w-4 h-4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 11h12V9a2 2 0 0 0-2-2h-3V4a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v17a1 1 0 1 0 2 0V4h9v3h-3a2 2 0 0 0-2 2v2Zm1.912 5a.912.912 0 0 1-.912-.912V13h2v2.037a.963.963 0 0 1-.963.963h-.125Zm4 3a.912.912 0 0 1-.912-.912V13h2v5.037a.963.963 0 0 1-.963.963h-.125ZM18 15.088c0 .504.408.912.912.912h.125a.963.963 0 0 0 .963-.963V13h-2v2.088Z" fill="currentColor" />
                </svg>
              ),
              label: `${data?.details.suites}`,
            },
            {
              icon: (
                <svg viewBox="0 0 24 24" className="w-4 h-4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM9 13.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" fill="currentColor" />
                  <path d="M15.843 2H8.157a3 3 0 0 0-2.935 2.38L4.445 8.05A3.001 3.001 0 0 0 2 11v9a2 2 0 0 0 2 2h1a1 1 0 0 0 1-1v-2h12v2a1 1 0 0 0 1 1h1a2 2 0 0 0 2-2v-9a3 3 0 0 0-2.445-2.949l-.777-3.672A3 3 0 0 0 15.843 2ZM19 10a1 1 0 0 1 1 1v6H4v-6a1 1 0 0 1 1-1h14Zm-1.5-2h-11l.678-3.207a1 1 0 0 1 .862-.786L8.157 4h7.686a1 1 0 0 1 .948.68l.03.113L17.5 8Z" fill="currentColor" />
                </svg>
              ),
              label: `${data?.details.garages}`,
            },
          ].map((detail, index) => (
            <p key={index} className="flex gap-1 items-center">
              {detail.icon}
              {detail.label}
            </p>
          ))} */}
        </div>
        <p className="text-base truncate max-w-full font-bold">
        {data.price == 0 && "Sob consulta"}
        {data.price > 0 &&data.price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
        </p>
      </Link>
    </div>
  );
};

export default CardView;
